<template>
  <main>
    <b-card>
      <h1 class="text-center text-2xl">Comprar inventario</h1>
      <h2 class="text-center text-lg">
        Completa los siguientes pasos para comprar inventario
      </h2>

      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        on-text="Confirmar"
        finish-button-text="Confirmar"
        next-button-text="Siguiente"
        back-button-text="Regresar"
        class="mt-2"
        @on-complete="onSubmit"
      >
        <tab-content title="Tienda" :before-change="validateStore" class="py-2">
          <validation-observer ref="storeForm">
            <validation-provider
              name="Tienda"
              rules="required"
              v-slot="{ errors }"
            >
              <label for="store" class="text-lg">Tienda</label>

              <b-form-select
                v-model="selectedStore"
                :options="stores"
                value-field="id"
                text-field="name"
                placeholder="Selecciona una tienda"
              />

              <span class="d-block text-danger mt-50">{{ errors[0] }}</span>
            </validation-provider>
          </validation-observer>
        </tab-content>
        <tab-content
          v-if="!providerParam"
          title="Tipo de vendedor"
          :before-change="validateSellerType"
          class="py-2"
        >
          <validation-observer ref="sellerTypeForm">
            <validation-provider
              name="Tipo de vendedor"
              rules="required"
              v-slot="{ errors }"
            >
              <label for="sellerType" class="text-lg">Tipo de vendedor</label>

              <b-form-select
                v-model="selectedSellerType"
                :options="sellerTypes"
                value-field="value"
                text-field="label"
                placeholder="Selecciona un tipo de vendedor"
              />

              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </validation-observer>
        </tab-content>
        <tab-content
          v-if="!providerParam && selectedSellerType === 'provider'"
          title="Proveedor"
          :before-change="validateProvider"
          class="py-2"
        >
          <validation-observer ref="providerForm">
            <validation-provider
              name="Proveedor"
              rules="required"
              v-slot="{ errors }"
            >
              <label for="provider" class="text-lg">Proveedor</label>

              <b-form-select
                v-model="selectedProvider"
                :options="providers"
                value-field="id"
                text-field="name"
                placeholder="Selecciona un proveedor"
              />

              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-card>
  </main>
</template>

<script>
import { mapActions } from "vuex"
import vSelect from "vue-select"
import { WizardStep, FormWizard, TabContent } from "vue-form-wizard"
import { ValidationObserver, ValidationProvider } from "vee-validate"

export default {
  components: {
    WizardStep,
    FormWizard,
    TabContent,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    const providerParam = this.$route.query.provider

    return {
      stores: [],
      selectedStore: null,
      selectedProvider: null,
      selectedSellerType: providerParam ? "provider" : null,
      providers: [],
      providerParam,

      sellerTypes: [
        { label: "Proveedor", value: "provider" },
        { label: "Establecimiento", value: "establishment" },
      ],
    }
  },
  created() {
    Promise.all([
      this.fetchShortStores({
        by_establishment: this.$route.params.id,
        meta: {
          pagination: {
            page: 1,
            per_page: 100000,
          },
        },
      }),
      this.getSuppliers(),
    ]).then(([storesRes, suppliersRes]) => {
      this.stores = storesRes.data

      this.selectedProvider = this.providerParam
    })
  },
  computed: {},
  methods: {
    ...mapActions("stores", ["fetchReferralToken", "fetchShortStores"]),
    ...mapActions("users", ["fetchSuppliers"]),

    onSubmit() {
      switch (this.selectedSellerType) {
        case "provider":
          const establishmentSlug = this.providers.find(
            (provider) => provider.id === this.selectedProvider
          )?.supplier_info?.establishment_slug

          this.$router.push({
            name: "establishment-landing-page",
            params: { slug: establishmentSlug },
            query: { from_store_id: this.selectedStore },
          })
          break
        case "establishment":
          this.$router.push({
            name: "establishment-landing-page",
            params: { slug: "marketplace" },
            query: { from_store_id: this.selectedStore },
          })
          break
      }
    },

    getSuppliers({ page = 1 } = {}) {
      return this.fetchSuppliers({
        by_resource_id: this.$route.params.id,
        by_active_status: true,
        by_role: "establishment_supplier",
        meta: {
          pagination: {
            page: page || 1,
            per_page: 100000,
          },
        },
      }).then((res) => {
        this.providers = res.data
      })
    },

    validateStore() {
      return this.$refs.storeForm.validate().then((success) => {
        return success
      })
    },
    validateSellerType() {
      return this.$refs.sellerTypeForm.validate().then(async (success) => {
        return success
      })
    },
    validateProvider() {
      return this.$refs.providerForm.validate().then(async (success) => {
        return success
      })
    },
  },
}
</script>

<style lang="scss">
@media (max-width: 768px) {
  .vue-form-wizard {
    .wizard-header {
      display: none;
    }

    ul.wizard-nav-pills,
    .wizard-progress-with-circle {
      display: none !important;
    }
  }
}

.wizard-tab-content {
  padding: 1rem !important;
}
</style>
